import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import gql from "graphql-tag"

export const basicCompSetFragment = gql`
  fragment basicCompSetFragment on CompSet {
    _id
    slug
    name
    start
    end
    isGradingHolds
    isRelativeScoring
    relativeScore
    numClimbsBeforeBump
    judgeType
    categoriesAllowed
    competitorType
    pointsPerGradeDiff
    isGradingAttempts
    attemptPoints
    bonusForFlashing
    maxTimesClimbScored
    numClimbsToScore
    numZoneHolds
    numClimbsToScorePerTeam
    invitedCompetitorIds
    invitedTeamIds
    isWorldCupSport
    isWorldCupBouldering
    isWorldCupBoulderingPoints
    numZoneHolds
    zoneHoldPoints
    topPoints
    hasSponsoredClimbs
    isShowingStarRating
    starRatingLabel
    isClimbsEnabledBeforeStart
    isRelativeScoreMaxScore
    isWarningOfBump
    isBumpingDisabled
    isExcludedFromOverall
    numCompScores
    isTimeLeft
    bonusActivities {
      _id
      name
      description
      points
    }
    scoreBonuses {
      _id
      name
      description
      points
    }
    scannerActivities {
      _id
      type
      name
      minLogRate
      points
      image
      numScanners
    }
    gymAreas {
      _id
      slug
      name
      sortOrder
      picture
      popMedia {
        url
      }
      problems {
        _id
        type
        name
        slug
        picture
        setterGrade
        gradeColor
        gradeName
        color
        numHolds
        isSponsored
        isDraft
        gymArea {
          _id
          name
        }
        gym {
          _id
          name
        }
        comps {
          _id
          name
        }
        compSets {
          _id
          name
        }
        maxCompScores {
          compSet
          maxCompScore
        }
      }
      routes {
        _id
        type
        name
        slug
        picture
        setterGrade
        gradeColor
        gradeName
        color
        maxCompScore
        numHolds
        isSponsored
        isDraft
        gymArea {
          _id
          name
        }
        gym {
          _id
          name
        }
        comps {
          _id
          name
        }
        compSets {
          _id
          name
        }
        maxCompScores {
          compSet
          maxCompScore
        }
      }
    }
    comp {
      _id
      slug
      name
      isTeamComp
      categories
      picture
      howToScoreYTID
      howToJudgeScoreYTID
      isRelativeScoringEnabled
      isShowingOverallScores
      startDate
      endDate
      isUsingScanners
      isHidingLeaderboard
      judges {
        ...basicUserFragment
      }
      gym {
        _id
        isShowingScannerPoints
        gymAreas {
          _id
          name
          picture
          parentArea {
            _id
          }
        }
      }
      notifications {
        _id
        title
        description
        image
        link
        expirationDate
        creator {
          _id
        }
      }
      gyms {
        _id
        name
        slug
        type
        isShowingScannerPoints
        isTopoMapEnabled
        popMedia {
          ...basicMediaFragment
        }
        gymAreas {
          _id
          name
          picture
          parentArea {
            _id
          }
        }
        scannerActivities {
          _id
          type
          name
          minLogRate
          points
          image
          numScanners
        }
      }
    }
  }
  ${basicMediaFragment}
  ${basicUserFragment}
`

export default {
  basicCompSetFragment,
}
